import * as React from "react"
import { HeadFC, useStaticQuery, graphql } from "gatsby"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import Layout from "../components/layout"
import {SEO} from "../components/seo"
import Intro from "../components/intro"
import ForedragItem from "../components/foredragItem"
import Container from "../components/container"
import Carousel from "../components/carousel"
import Book from "../components/book"
import Deltag from "../components/deltag"
import Kontakt from "../components/kontakt"

const intro = {
  heading: "Foredrag",
  description: "<h2>Hør om Danmarks involvering i Den Kolde Krig.</h2>"
}


const Foredrag = () => {
  const { foreDrag } = useSiteMetadata()
  
  return (
      <Layout>
        <Intro 
          heading={intro?.heading} 
          description={intro?.description} 
          sm
        />
        <Container>
          {foreDrag.map((item:any, index:number) => (
            <React.Fragment key={index}>
              <ForedragItem
                id={index}
                title={item.title}
                text={item?.text}
                other={item.other}
                image={item.image}
              />
            </React.Fragment>
          ))}
          <Carousel/>
        </Container>
        

        <Deltag invert/>
        <Book/>
        <Kontakt/>
    </Layout>
  )
}

export default Foredrag


export const Head: HeadFC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return (
    <SEO title={`Foredrag | ${site.siteMetadata.title}`} description={`Bestil billet til at høre om Danmarks involvering i Den Kolde Krig. Eller kontakt Niels Jensen, hvis du ønsker at organisere et foredrag for en forsamling.` || site.siteMetadata.description}/>
  )
}
